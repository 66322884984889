import React, { useEffect, useRef, createRef, useContext  } from "react";
import { TimelineMax, TweenLite, Power3, gsap } from "gsap";
import { GlobalDispatchContext, GlobalStateContext } from "./context";
import { CSSPlugin } from 'gsap/CSSPlugin';

function LandingAnimation() {
    let _homeHero = useRef(null);
    let heroTextTop = useRef(null);
    let heroTextParent = useRef(null);
    let _progressBar = useRef(null);
    let _percentage = useRef(null);
    let timeline = new TimelineMax({ paused: true });
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);

    const countItUp = () => {
        let i = 0;
        if (i === 0 ) {
            i = 1;
            var width = 1;
            var id = setInterval(frame, 25);
            function frame() {
            if (width >= 100) {
                clearInterval(id);
                i = 0;
            } else {
                width++;
                 _percentage.current.innerHTML = _progressBar.current.style.width ;
            }
            }
        }
    }

    useEffect(() => {
        gsap.registerPlugin( CSSPlugin )
        if (state.landingAnimationCount < 1){
            timeline
            .add(TweenLite.to(_homeHero, 0.7, { css: { width: "100vw" }, ease: Power3.easeOut, delay: 1 }))
            .call(() => { countItUp() }, null, null, 1)
            .add(TweenLite.to(_progressBar.current, 1.1, { css: { visibility: "visible", width: "100%", transitionTimingFunction: "cubic-bezier(.05,.95,.75,.86)" }, ease: Power3.easeOut, delay: 0.6 }))
            .add(TweenLite.to(_progressBar.current, 0.1, { css: { opacity: "0", transition: "0.5s all ease-in-out" }, ease: Power3.easeOut, delay: 1 }))
            .add(TweenLite.to(heroTextTop, 0.9, { css: { lineHeight: "2.2rem" }, ease: Power3.easeOut, delay: 0.75 }))
            .add(TweenLite.to(heroTextParent, 0.3, { css: { opacity: "0", y: 50 }, ease: Power3.easeOut, delay: 0.4 }))
            .add(TweenLite.to(_homeHero, 1, { css: { height: "0"}, ease: Power3.easeOut, delay: 0.2 }))
            .add(TweenLite.to(_homeHero, 0, { css: { visibility: "hidden"}, delay: 0.2}))
            .call(() => { dispatch({type: "landing_animation_count"}) }, null, null, 1)
            .play();
        }
    }, [timeline, countItUp, state.landingAnimationCount])

    return (
        <div className="Home-hero" ref={ el => _homeHero = el }>
            <div ref={ el => heroTextParent = el }>
                <p className="Hero-text" ref={ el => heroTextTop = el }>welcome.</p>
            </div>
            <div className="Progress-bar" ref={ _progressBar }>
                <p className="Progress-bar-percentage" ref={ _percentage }></p>
            </div>
        </div>
    )
};

export default LandingAnimation;
