import React, { useEffect, useRef, useContext } from 'react';
import { TweenLite, TimelineMax, Power3, gsap } from "gsap";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { GlobalStateContext } from "./context";


function Navbar({ navMenuOpen, toggleNavMenu }) {
    const count = 2;
    const state = useContext(GlobalStateContext);
    let _nav = useRef(null);
    let timeline = new TimelineMax({ paused: true });

    useEffect(() => {
        gsap.registerPlugin( CSSPlugin )
        timeline.add(TweenLite.to(_nav, 1, { css: { y: 0 }, ease: Power3.easeOut, delay: state.landingAnimationCount < count ? 8.7 : 1 })).play() //8 default
    }, [])

    return (
        <div className="Navbar-container-parent">
            <div className="Navbar-container" ref={ el => _nav = el}>
                <div id="rashad-rose">rashad rose.</div>
                <div className="Left-nav-container">
                    <a href="mailto:rashadd.rose@gmail.com">
                        <div className={`Button`} id="Contact">contact</div>
                    </a>
                    <div className="Burger-menu-container" id="Orginal-burger" onClick={ () => toggleNavMenu()}>
                        <div className={`Burger-menu ${navMenuOpen ? "Open" : "" }`}></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Navbar;
