/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { TweenLite, TweenMax, Power3, gsap } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import { isMobileOnly } from "react-device-detect"

import { GlobalStateContext } from "./context"
import Navbar from "./navbar"
import LandingAnimation from "../components/landingAnimation"
import Social from "../components/social"
import "../styles/index.scss"

const Layout = ({ children }) => {
  const state = useContext(GlobalStateContext)
  const [navMenuOpen, setNavMenuOpen] = useState(false)
  let _main = useRef(null)
  let _navContainer = useRef(null)
  let _social = useRef(null)

  const toggleNavMenu = () => {
    if (!navMenuOpen) {
      setNavMenuOpen(true)
      if (typeof document !== "undefined") {
        if (typeof document !== "undefined") {
          if (document.getElementById("fp-nav"))
            document.getElementById("fp-nav").style.visibility = "hidden"
          document.querySelector("body").style.overflow = "hidden"
        }
      }
      openNavAnimation()
    }
    if (navMenuOpen) {
      if (typeof document !== "undefined") {
        if (document.getElementById("fp-nav"))
          document.getElementById("fp-nav").style.visibility = "visible"
        document.querySelector("body").style.overflow = "visible"
      }

      openNavAnimation()
      setTimeout(() => {
        setNavMenuOpen(false)
      }, 1000)
    }
  }

  const openNavAnimation = () => {
    if (!navMenuOpen) {
      TweenLite.to(_main, 0, {
        css: {
          x: "-200",
          transition: "0.6s all cubic-bezier(.34,-0.44,.57,1.07)",
        },
        delay: 0.2,
      })
      TweenLite.to(_social, 0, {
        css: {
          x: "-200",
          transition: "0.6s all cubic-bezier(.34,-0.44,.57,1.07)",
        },
        delay: 0.2,
      })
      TweenLite.to(_navContainer, 0, {
        css: {
          x: "-200",
          transition: "0.6s all cubic-bezier(.34,-0.44,.57,1.07)",
        },
        delay: 0.2,
      })
    } else {
      TweenLite.to(_main, 0, {
        css: { x: "0", transition: "0.2s all ease-out" },
        delay: 0.2,
      })
      TweenLite.to(_social, 0, {
        css: { x: "0", transition: "0.2s all ease-out" },
        delay: 0.2,
      })
      TweenLite.to(_navContainer, 0, {
        css: { x: "0", transition: "0.2s all ease-out" },
        delay: 0.2,
      })
      setTimeout(() => {
        if (typeof document !== "undefined") {
          if (document.querySelector("main").style)
            document.querySelector("main").style.transform = "none"
        }
      }, 500)
    }
  }

  const navMenu = () => {
    return (
      <div className="Nav-menu-container">
        <ul className="Nav-menu-ul">
          <Link to="/">
            <li
              className="Nav-menu-li Projects"
              onClick={() => toggleNavMenu()}
            >
              projects.
            </li>
          </Link>
          <Link to="/about">
            <li
              className="Nav-menu-li Projects"
              onClick={() => toggleNavMenu()}
            >
              about.
            </li>
          </Link>
          <a
            href="https://resume.creddle.io/resume/awq9dml69ev"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="Nav-menu-li Resume" onClick={() => toggleNavMenu()}>
              resume.
            </li>
          </a>
          <a href="mailto:rashadd.rose@gmail.com">
            <li className="Nav-menu-li Contact" onClick={() => toggleNavMenu()}>
              contact.
            </li>
          </a>
        </ul>
      </div>
    )
  }

  useEffect(() => {
    gsap.registerPlugin(CSSPlugin)
    document.documentElement.style.setProperty(
      "--fade-in-delay",
      state.landingAnimationCount < 2 ? null : 1 + "s"
    )
    TweenMax.to(_social, 1, {
      bottom: "10%",
      visibility: "visible",
      ease: Power3.easeOut,
      delay: 9.5,
    })
  }, [])

  return (
    <>
      <div
        ref={el => (_navContainer = el)}
        className="Navbar-container-parent"
        id="Outer"
      >
        <Navbar
          toggleNavMenu={toggleNavMenu}
          openNavAnimation={openNavAnimation}
          navMenuOpen={navMenuOpen}
          setNavMenuOpen={setNavMenuOpen}
        />
      </div>
      {navMenuOpen && navMenu()}
      {state.landingAnimationCount < 2 && <LandingAnimation />}
      <React.Fragment>
        <main ref={el => (_main = el)}>{children}</main>
        <div className="Hide-social" ref={el => (_social = el)}>
          <Social />
        </div>
      </React.Fragment>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
