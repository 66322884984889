import React from 'react';

const Social = () => {
    return (
         <div className="Social-container">
            <div className="Social-links">
                <a href="https://github.com/nycodinguru" target="_blank" rel="noopener noreferrer">
                    <div className="Social Github">Github</div>
                </a>
                <a href="https://www.linkedin.com/in/rashadrose/" target="_blank" rel="noopener noreferrer">
                    <div className="Social Linkedin">Linkedin</div>
                </a>
                <a href="https://angel.co/rashad-rose" target="_blank" rel="noopener noreferrer">
                    <div className="Social Angel-list">Angel List</div>
                </a>
                <a href="https://www.instagram.com/rozvyyy/" target="_blank" rel="noopener noreferrer">
                    <div className="Social Instagram">Instagram</div>
                </a>
            </div>
        </div>
    )
};

export default Social;
